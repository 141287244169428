import { Link } from 'gatsby';
import React from 'react';

const Header = () => (
  <header className="header">
    <div className="header-container">
      <h1 className="header-title">
        <Link to="/" className="header-title-link">
          carboncopy
        </Link>
      </h1>
    </div>
  </header>
);

export default Header;
