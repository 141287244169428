import React from 'react';
import PropTypes from 'prop-types';
import Header from './header';

const Layout = ({ children, header }) => (
  <>
    {header ? <Header /> : null}
    <main>{children}</main>
  </>
);

Layout.propTypes = {
  header: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  header: true,
};

export default Layout;
